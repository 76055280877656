html[dark-mode-theme="dark"] .screen {
  height: 100px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  background-color: #4357692d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* color: white; */
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 2.2rem;
  box-sizing: border-box;
}

/* html[dark-mode-theme="light"] .screen { */
html .screen {
  height: 100px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  background-color: #4357692d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* color: white; */
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 2.2rem;
  box-sizing: border-box;
}

html[global-theme="hand"] {
  font-size: 1.3rem;
}

html[global-theme="hand"] .screen{ 
  border-radius: 255px 15px 225px 15px / 15px 225px 15px 255px;
  border: solid 1px black;
  background-color: transparent;
  font-family: 'Gochi Hand', cursive;
}
html[global-theme="hand"][dark-mode-theme="light"] .screen{ 
  border-radius: 255px 15px 225px 15px / 15px 225px 15px 255px;
  border: solid 1px white;
  background-color: transparent;
  font-family: 'Gochi Hand', cursive;
}