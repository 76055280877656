nav{
  display: flex;
  justify-content: space-between;
}

/* Variables de color si el tema es nulo o light */
html {
  --primary-color: #282c34;
  --secondary-color: #fff;
  --tertiary-color: rgb(80, 60, 209);
  --tertiary-color-hover:rgb(61, 43, 184);
  --quaternary-color: rgb(243, 61, 29);
  --quaternary-color-hover: rgb(228, 39, 15);
}

/* Variables de color si el tema es dark */
html[dark-mode-theme="dark"] {
  --primary-color: #fff;
  --secondary-color: #282c34;
  --tertiary-color: rgb(138, 127, 209);
  --tertiary-color-hover:rgb(104, 89, 202);
  --quaternary-color: rgb(239, 90, 64);
  --quaternary-color-hover: rgb(243, 73, 51);
}

/* Variables de color si el tema es secondary and dark */
html[global-theme="secondary"][dark-mode-theme="dark"] {
  --primary-color: rgb(135, 65, 65);
  --secondary-color: #7e91b7;
  --tertiary-color: rgb(80, 79, 86);
  --tertiary-color-hover:rgb(77, 73, 95);
  --quaternary-color: rgb(97, 87, 158);
  --quaternary-color-hover: rgb(66, 54, 146);
}

/* Variables de color si el tema es secondary and light */
html[global-theme="secondary"] {
  --primary-color: rgb(203, 179, 179);
  --secondary-color: #4e5d7a;
  --tertiary-color: rgb(144, 140, 169);
  --tertiary-color-hover:rgb(104, 89, 202);
  --quaternary-color: rgb(209, 148, 16);
  --quaternary-color-hover: rgb(187, 118, 8);
}
/* quaternary, quinary, senary, septenary, octonary, nonary, denary */