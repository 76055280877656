.theme-wrapper {
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
}

.theme-label{
  margin-right: 5px;
  color: var(--secondary-color);
}

#darkmode-toggle{
  display: none;
}

.bi-brightness-high{
  color: yellow;
}

.bi-moon-stars{
  color: rgb(61, 43, 184);
}