button {
  border: none;
  /* background-color: rgb(80, 60, 209); */
  background-color: var(--tertiary-color);
  font-size: 24px;
  color: var(--secondary-color);
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
}

button:hover {
  /* background-color: rgb(61, 43, 184); */
  background-color: var(--tertiary-color-hover);
}

.equals {
  grid-column: 3 / 5;
  /* background-color: rgb(243, 61, 29); */
  background-color: var(--quaternary-color);
}

.equals:hover {
  background-color: var(--quaternary-color-hover);
}

html[global-theme="hand"] button{ 
  border-radius: 255px 15px 225px 15px / 15px 225px 15px 255px;
  border: solid 1px black;
  background-color: transparent;
  font-family: 'Gochi Hand', cursive;
}
html[global-theme="hand"][dark-mode-theme="light"] button{ 
  border-radius: 255px 15px 225px 15px / 15px 225px 15px 255px;
  border: solid 1px white;
  background-color: transparent;
}

html[global-theme="hand"] button:hover {
  background-color: rgb(241, 241, 241);
}

html[global-theme="hand"][dark-mode-theme="light"] button:hover {
  background-color: rgb(74, 74, 74);
}